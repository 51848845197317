<template>
  <r-container>
    <r-row class="v-baseline" v-for="(item, i) in info" :key="i">
      <r-col class="col-1">
        <r-btn class="color-error-text" icon @click="remove(i)">
          <r-icon v-html="$r.icons.delete"></r-icon>
        </r-btn>
      </r-col>
      <r-col class="col-3">{{ item.title }}</r-col>
      <r-col class="col-3">{{ item.count }} {{ $t(item.unit) }}</r-col>
      <r-col class="col-3">
        {{ item.count * item.fee }} {{ $t(this.$r.store.unit_price) }}
      </r-col>
      <r-col class="col-2"
        >{{ $t("used") }}:
        {{ "remain" in item ? item.count - item.remain : 0 }}</r-col
      >
    </r-row>
    <r-row class="v-end">
      <r-col class="col-4">
        <r-select
          v-model="raw"
          :label="$t('raw')"
          searchLink="office/raws/search"
          text="title"
        ></r-select>
      </r-col>
      <r-col class="col-3">
        <r-number v-model="num" :label="raw ? $t(raw.unit) : ''"></r-number>
      </r-col>
      <r-col class="col-3">
        <r-number v-model="fee" label="fee"></r-number>
      </r-col>
      <r-col class="col-2">
        <r-btn
          :disabled="num === null || fee === null"
          class="color-info"
          @click="add"
          >{{ $t("add") }}</r-btn
        >
      </r-col>
    </r-row>
  </r-container>
</template>

<script>
export default {
  name: "r-raw",
  props: {
    modelValue: Array,
  },
  data() {
    return {
      raw: null,
      num: null,
      fee: null,
      info: this.modelValue ? this.modelValue : [],
    };
  },
  methods: {
    add() {
      this.info.push({
        _id: this.raw.value,
        title: this.raw.title,
        unit: this.raw.unit,
        count: this.num,
        fee: this.fee,
      });
      this.raw = null;
      this.num = null;
      this.fee = null;
      this.$emit("update:model-value", this.info);
    },
    remove(i) {
      this.info.splice(i, 1);
      this.$emit("update:model-value", this.info);
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/_include.scss";
</style>
