<template>
  <div>
    <r-number
      :label="$t('price')"
      v-model="info.price"
      :rules="[required ? 'required' : '']"
      :min="0"
    ></r-number>
    <r-select
      :label="$t('payment_type')"
      v-model="info.type"
      :items="['cash', 'check', 'debt']"
      translate
      just-value
      :rules="[required ? 'required' : '']"
    ></r-select>
    <div v-if="info.type === 'check' || info.type === 'debt'">
      <r-date-picker
        :label="$t('payment_date')"
        v-model="info.date"
        :rules="['required']"
      ></r-date-picker>
      <r-text-input
        :label="$t('payment_desc')"
        v-model="info.desc"
      ></r-text-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "r-payment",
  props: {
    required: Boolean,
    modelValue: Object,
  },
  data() {
    return {
      info: this.modelValue ? this.modelValue : {},
    };
  },
  watch: {
    info: {
      deep: true,
      handler: function (n) {
        this.$emit("update:model-value", n);
      },
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/_include.scss";
</style>
